<template>
  <div class="jiaoxue">
    <headerNav></headerNav>
    <el-carousel :interval="5000" arrow="always" height="750px">
      <el-carousel-item v-for="item in bannerList" :key="item">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>

    <div class="titleBj">
      <div class="titleWz">联系我们</div>
    </div>
    <div class="mapList">
      <div class="left">
        <div class="left1">常营园校区</div>
        <div class="left2">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/cyMap.png"
            class="mapImg"
          />
          <div class="lxfs">联系方式：010-53381073</div>
          <div class="lxfs">地址信息：北京市朝阳区常意路4号院3号楼</div>
        </div>
      </div>
      <div class="right">
        <div class="right1">东坝园校区</div>
        <div class="right2">
          <img
            src="https://yyyey.oss-cn-beijing.aliyuncs.com/yeyMapPcTwo.png"
            class="mapImg"
          />
          <div class="lxfs">联系方式：010-53381073</div>
          <div class="lxfs">地址信息：朝阳区管庄路181号院内8号楼</div>
        </div>
      </div>
    </div>
    <div class="ewmList">
      <div class="ewmLeft">
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/24735a19716e6ba71396164827cd487.jpg"
          class="ewmImg"
        />
        <div class="ewmWz">官方报名咨询</div>
      </div>
      <div class="ewmRight">
        <img
          src="https://yyyey.oss-cn-beijing.aliyuncs.com/xiaohongshu.png"
          class="ewmImg"
        />
        <div class="ewmWz">官方小红书</div>
      </div>
    </div>
    <div class="empty"></div>
    <footerBtm></footerBtm>
  </div>
</template>

<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";
export default {
  name: "jiaoxue",
  components: { headerNav, footerBtm },
  data() {
    return {
      bannerList: [],
    };
  },
  mounted() {
    this.api.allPage({ name: "联系我们" }).then((res) => {});
    // banner
    this.api.banner({ remark1: 7 }).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].img = JSON.parse(res.data[i].img);
      }
      this.bannerList = res.data;
    });
  },
  methods: {},
};
</script>
<style scoped>
div /deep/ .el-carousel__container {
  height: 750px !important;
}
div /deep/ .el-carousel__container img {
  height: 100% !important;
}
.el-carousel,
.el-carousel__item,
.el-carousel img {
  width: 100%;
}
.jiaoxue {
  overflow-x: hidden;
}
.titleBj {
  width: 922px;
  height: 140px;
  /* background-color: black; */
  margin: 0 auto;
  /* display: flex; */
}

.titleWz {
  width: 922px;
  height: 100px;
  font-size: 60px;
  font-weight: 500;
  color: #25ae85;
  line-height: 140px;
  margin: 0 auto;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/jiaoxuelinian/pcLianxiwomenTitle.png");
  background-size: 100% 100%;
  text-align: center;
  margin-top: 30px;
}

.mapList {
  width: 1920px;
  height: 630px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  margin-top: 91px;
}

.left1 {
  width: 284px;
  height: 60px;
  background: #25ae85;
  border-radius: 20px 20px 0px 0px;
  opacity: 1;
  margin: 0 auto;
  font-size: 40px;
  color: aliceblue;
  text-align: center;
  line-height: 75px;
  font-weight: 500;
}

.left2 {
  width: 610px;
  height: 540px;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 20px solid #25ae85;
  background-color: #25ae85;
}

.right1 {
  width: 284px;
  height: 60px;
  background: #ffe500;
  border-radius: 20px 20px 0px 0px;
  opacity: 1;
  margin: 0 auto;
  font-size: 40px;
  color: black;
  text-align: center;
  line-height: 75px;
}

.right2 {
  width: 610px;
  height: 540px;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 20px solid #ffe500;
  background-color: #ffe500;
}

.right {
  padding-left: 60px;
}
.left2 .lxfs {
  color: white;
}
.lxfs {
  width: 461px;
  height: 23px;
  font-size: 23px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  line-height: 23px;
  padding-top: 12px;
  padding-left: 40px;
}
.mapImg {
  width: 610px;
  height: 471px;
}
.ewmList {
  width: 1340px;
  display: flex;
  padding-top: 70px;
  margin: 0 auto;
}
.ewmLeft {
  width: 630px;
  height: 460px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.ewmRight {
  width: 630px;
  height: 460px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding-left: 100px;
}
.ewmWz {
  width: 280px;
  height: 40px;
  font-size: 40px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  line-height: 40px;
  margin: 0 auto;
  text-align: center;
}
.ewmImg {
  width: 400px;
  height: 400px;
  margin: 0 auto;
  display: block;
  margin-bottom: 5px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

.empty {
  height: 150px;
}

@media screen and (max-width: 1680px) {
  .mapList{
    width: 1680px;
  }
}
@media (min-width: 1366px) and (max-width: 1440px) {
  .mapList {
    width: 100%;
  }
  .left1 {
    font-size: 36px;
  }
  .left2 {
    width: 500px;
  }
  .mapImg {
    width: 500px;
  }
  .right1 {
    font-size: 36px;
  }
  .right2 {
    width: 500px;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
  div /deep/ .el-carousel__container {
    height: 563px !important;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 534px;
  }
  div /deep/ .el-carousel__container {
    height: 534px !important;
  }
  .titleBj {
    width: 910px;
    height: 100px;
    margin-top: 57px;
  }
  .titleWz {
    width: 910px;
    height: 100px;
    font-size: 43px;
    text-align: center;
  }
  .mapList {
    height: 448px;
    margin-top: 65px;
  }
  .right {
    padding-left: 43px;
  }
  .left1,
  .right1 {
    width: 202px;
    font-size: 28px;
  }
  .left2,
  .right2 {
    width: 434px;
    height: 377px;
    border-radius: 14px;
  }
  .mapImg {
    width: 434px;
    height: 297px;
  }
  .lxfs {
    width: 434px;
    font-size: 17px;
    padding-left: 28px;
  }
  .ewmList {
    width: 911px;
    height: 327px;
    margin: 0 auto;
  }
  .ewmLeft,
  .ewmRight {
    height: 327px;
  }
  .ewmImg {
    width: 285px;
    height: 285px;
  }
  .empty {
    height: 47px;
  }
  .ewmWz {
    font-size: 28px;
  }
}

@media (min-width: 1080px) and (max-width: 1280px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 500px;
  }
  div /deep/ .el-carousel__container {
    height: 500px !important;
  }
  .titleBj {
    width: 853px;
    height: 93px;
    margin-top: 53px;
  }
  .titleWz {
    width: 853px;
    height: 93px;
    font-size: 40px;
    text-align: center;
  }
  .mapList {
    width: 100%;
    height: 448px;
    margin-top: 65px;
  }
  .right {
    padding-left: 40px;
  }
  .left1,
  .right1 {
    width: 202px;
    font-size: 28px;
  }
  .left2,
  .right2 {
    width: 407px;
    height: 353px;
    border-radius: 14px;
  }
  .mapImg {
    width: 407px;
    height: 279px;
  }
  .lxfs {
    width: 407px;
    font-size: 16px;
    padding-left: 28px;
  }
  .ewmList {
    width: 853px;
    height: 307px;
    margin: 0 auto;
    padding-top: 47px;
  }
  .ewmLeft,
  .ewmRight {
    height: 307px;
  }
  .ewmImg {
    width: 267px;
    height: 267px;
  }
  .empty {
    height: 47px;
  }
  .ewmWz {
    font-size: 28px;
  }
}

@media screen and (max-width: 750px) {
  div /deep/ .el-carousel__container {
    height: 146px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }
  .titleBj {
    width: 100%;
    height: 47px;
  }
  .titleWz {
    width: 100%;
    height: 47px;
    font-size: 20px;
    line-height: 20px;
    margin-top: 16px;
    padding-top: 20px;
    box-sizing: border-box;
  }
  .mapList {
    width: 100%;
    height: 248px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    justify-content: space-between;
    margin-top: 31px;
  }
  .left1 {
    width: 95px;
    height: 34px;
    font-size: 13px;
    line-height: 45px;
  }
  .left2 {
    width: 164px;
    height: 235px;
    border: none;
    border-radius: 7px;
  }
  .mapImg {
    width: 144px;
    height: 123px;
    margin-left: 10px;
    margin-top: 10px;
  }
  .right {
    padding-left: 0;
  }
  .right1 {
    width: 95px;
    height: 34px;
    font-size: 13px;
    line-height: 45px;
  }
  .right2 {
    width: 164px;
    height: 235px;
    border: none;
    border-radius: 7px;
  }

  .lxfs {
    width: 100%;
    font-size: 12px;
    padding-left: 10px;
    box-sizing: border-box;
  }

  .ewmList {
    width: 100%;
    padding-top: 30px;
    justify-content: space-around;
  }
  .ewmLeft {
    width: 133px;
    height: 133px;
  }
  .ewmImg {
    width: 100%;
    height: 100%;
  }

  .ewmRight {
    width: 133px;
    height: 133px;
    padding-left: 0;
  }

  .ewmWz {
    width: 133px;
    font-size: 18px;
    text-align: center;
  }
  .empty {
    height: 60px;
  }
}
</style>
